import request from '../utils/request'
import config from './config'

// 更新日志详情
export function logUpdateDetails(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionAppVersionControl/logUpdateDetails`,
        method: 'get',
        params,
    })
}

// 更新日志时间列表
export function timeList(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionAppVersionControl/timeList`,
        method: 'get',
        params,
    })
}

// 获取最新一次更新信息
export function updateInformation(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionAppVersionControl/updateInformation`,
        method: 'get',
        params,
    })
}