<template>
    <div class="layout">
        <div class="header">
            <div class="header-left">
                <div class="logo">
                    <img src="../assets/imgs/logo.png" alt="" />
                    <span>慧年民政监管</span>
                </div>
                <div class="zhankai" @click="toFull">
                    <img src="../assets/imgs/zhankai.png" width="20px" height="20px" alt="" />
                </div>
            </div>
            <div class="header-center">
                <!-- 左边按钮 -->
                <div v-show="showLeftIcon" @click="handleLeftClick" class="scroll-icon left-icon">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div ref="swiperScroll" class="left-right-swiper-scroll-box">
                    <!-- 中间滚动区域 -->
                    <div ref="swiperScrollContent" class="swiper-scroll-content">
                        <div
                            class="liItem"
                            :class="currentIndex === index ? 'activeItem' : 'inactiveItem'"
                            v-for="(item, index) in myList"
                            :key="index"
                            @click="changeChangjing(item, index)"
                        >
                            {{ item.title }}
                        </div>
                    </div>
                </div>

                <!-- 右边按钮 -->
                <div v-show="showRightIcon" @click="handleRightClick" class="scroll-icon right-icon">
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <!-- 账户信息 -->
            <el-popover placement="bottom" trigger="click" width="435">
                <div class="content">
                    <div class="lineItem">
                        <span>账号：{{ userInfo.username }}</span>
                        <el-button type="text" @click="dialogVisible = true">修改密码</el-button>
                    </div>
                    <div class="lineItem">
                        <span>姓名：{{ userInfo.name }}</span>
                    </div>
                    <div class="lineItem">
                        <span>所属单位：{{ userInfo.unitName }}</span>
                        <div class="rightContent">
                            <el-button v-if="userInfo.status === 2" type="text" @click="toEdit">修改信息</el-button>
                            <span v-if="userInfo.status !== 2" class="tip" :style="{ color: '#EFB32B' }">政府{{ ['', '待审核', '审核通过', '审核不通过'][userInfo.status] }}</span>
                        </div>
                    </div>
                    <div style="text-align: center">
                        <el-button type="text" class="del" @click.native="logout">退出登录</el-button>
                    </div>
                </div>
                <span slot="reference" class="user-head"> {{ userName }} <i class="el-icon-caret-bottom"></i> </span>
            </el-popover>
            <!-- 联系电话 -->
            <el-popover placement="bottom" width="300" trigger="hover">
                <div style="color: #333; font-size: 16px">
                    <p style="text-align: center; font-weight: bold">欢迎您来电咨询反馈</p>
                    <p>服务热线：19122514080</p>
                    <p>工作时间：9:00-17:30（工作日）</p>
                </div>
                <img slot="reference" class="phoneImg" src="../assets/imgs/phone.png" alt="" />
            </el-popover>
        </div>
        <div class="bottom-box">
            <div class="aside">
                <div class="menu">
                    <el-menu :default-active="activeRoute" :unique-opened="true" background-color="#222222" text-color="#fff">
                        <SideItem v-for="route in permissionList" :key="route.name" :item="route" />
                    </el-menu>
                </div>
            </div>
            <div class="container">
                <!-- 面包屑导航 -->
                <tagsView></tagsView>
                <div class="my-content">
                    <AppMain />
                </div>
            </div>
        </div>
        <!-- 修改密码弹窗 -->
        <ChangePassword :dialogVisible.sync="dialogVisible" />
        <!-- 更新日志 -->
        <el-dialog title="更新日志" :visible.sync="logVisible" width="40%">
            <div class="update-log">
                <el-row type="flex" justify="space-between" class="desc">
                    <span class="version">版本号：{{ version }}</span>
                </el-row>
                <div class="detail-info" v-html="description"></div>
                <div slot="footer" class="tc">
                    <el-button type="primary" @click="logVisible = false">知道了</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import tagsView from './compontents/TagsView/index'
import AppMain from './compontents/AppMain'
import router, { staticRoutes, resetRouter } from '@/router/index'
import SideItem from './compontents/sideItem.vue'
import ChangePassword from './compontents/changePassword.vue'
import Watermark from '@/utils/waterMarkmore'
import { getCn } from '@/utils'
import { updateInformation } from '@/api/updateLog'
export default {
    // 布局
    name: 'layout',
    components: {
        SideItem,
        ChangePassword,
        tagsView,
        AppMain
    },
    computed: {
        showLeftIcon() {
            return this.clickNum > 0
        },
        /*		routeList: () => {

            return router.options.routes
          },*/
        userName: () => {
            const userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
            return userInfo?.username || 'admin'
        },
        userInfo: () => {
            return JSON.parse(localStorage.getItem('cad_userInfo'))
        }
    },
    watch: {
        $route: {
            handler(val) {
                this.$nextTick(() => {
                    this.activeRoute = val.meta.activeIndex
                })
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            permissionList: [],
            activeRoute: '',
            dialogVisible: false,
            currentIndex: 0,
            myList: [],
            optionsList: [],
            allScenarioList: [],
            showRightIcon: false, // 是否显示右箭头
            swiperScrollWidth: 0, // 盒子的宽度
            swiperScrollContentWidth: 0, // 内容的宽度
            maxClickNum: 0, // 最大点击次数
            lastLeft: 0, // 上次滑动距离
            clickNum: 0, // 点击次数
            childrenList: [], // 传进来的子元素
            logVisible: false,
            description: '',
            version: ''
        }
    },
    mounted() {
        this.currentIndex = localStorage.getItem('currentIndex') * 1 || 0 //初始化选中样式
        this.$nextTick(() => {
            this.allScenarioList = JSON.parse(localStorage.getItem('scenarioList')) //获取场景
            this.myList = this.allScenarioList
            /*	if(this.allScenarioList.length && this.allScenarioList.length >= 4) {
            this.myList = this.allScenarioList.slice(0, 4)
            this.optionsList = this.allScenarioList.slice(4)
          }else {
            this.myList = this.allScenarioList
            this.optionsList = []
          }*/
            this.permissionList = JSON.parse(localStorage.getItem('initLeftMenu')) || this.myList[0].children //初始化显示第一个场景里面的第一个

            // 获取 HTMLCollection 转为 数组
            this.childrenList = this.$refs.swiperScrollContent.children
            console.log(this.$refs.swiperScrollContent.children, 'chidren<<<<')
            console.log('childrenList--->', this.childrenList)
            console.log('swiperScroll--->', this.$refs.swiperScroll.getBoundingClientRect())
            console.log('swiperScrollContent--->', this.$refs.swiperScrollContent.getBoundingClientRect())
            // 盒子的宽度
            this.swiperScrollWidth = this.$refs.swiperScroll.getBoundingClientRect().width
            console.log(this.swiperScrollWidth, 'swiperScrollWidth====>')

            // 内容的宽度
            this.swiperScrollContentWidth = this.$refs.swiperScrollContent.getBoundingClientRect().width
            // 比较盒子的宽度,跟内容的宽度：判断是否需要展示右边的按钮
            console.log(this.swiperScrollContentWidth, 'swiperScrollContentWidth====>')
            if (this.swiperScrollWidth <= this.swiperScrollContentWidth) {
                this.showRightIcon = true
            }
        })

        this.activeRoute = this.$route.path
        // 系统水印
        const text = this.getWaterMarkContent()
        Watermark.set('慧年', text)
        this.updateInformation()
    },
    methods: {
        // 获取日志更新
        async updateInformation() {
            const result = await updateInformation({
                appType: 5
            })
            // this.description = result.data.updateDescription
            // this.version = result.data.newVersion
            if (localStorage.getItem('HomeCareVersion') === null) {
                localStorage.setItem('HomeCareVersion', JSON.stringify(result.data))
                this.logVisible = true
                this.description = result.data.updateDescription
                this.version = result.data.newVersion
            } else {
                if (JSON.parse(localStorage.getItem('HomeCareVersion')).newVersion !== result.data.newVersion) {
                    localStorage.setItem('HomeCareVersion', JSON.stringify(result.data))
                    this.logVisible = true
                    this.description = result.data.updateDescription
                    this.version = result.data.newVersion
                }
            }
        },
        // 点击右箭头（左侧滚动）
        handleRightClick() {
            // 如果点击次数小于数组长度-1时，执行左滑动效果。
            console.log(this.childrenList.length, '??@@')
            if (this.clickNum < this.childrenList.length - 1) {
                // 获取当前元素宽度
                let width = this.childrenList[this.clickNum].getBoundingClientRect().width
                console.log(this.childrenList[this.clickNum], this.childrenList[this.clickNum].getBoundingClientRect())
                // 获取最后一个元素距离左侧的距离
                let lastItemOffsetLeft = this.childrenList[this.childrenList.length - 1].offsetLeft
                // 获取最后一个元素宽度
                let lastWidth = this.childrenList[this.childrenList.length - 1].getBoundingClientRect().width
                console.log('lastItemOffsetLeft-->', lastItemOffsetLeft, this.childrenList[this.childrenList.length - 1].getBoundingClientRect())
                // 获取可视区域宽度
                const lookWidth = this.$refs.swiperScroll.clientWidth
                console.log('获取可视区域宽度-->lookWidth', lookWidth)
                // 如果最后一个元素距离左侧的距离+自身的宽度大于可视距离，表示最后一个元素没有出现，执行滚动效果
                if (lastItemOffsetLeft + lastWidth > lookWidth) {
                    // 滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
                    this.$refs.swiperScrollContent.style.marginLeft = `${-width + this.lastLeft}px`
                    this.lastLeft = -width + this.lastLeft
                    // 点击次数+1
                    this.clickNum++
                    // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
                    this.maxClickNum = this.clickNum
                }
                // 如果最后一个元素距离左侧的距离小于于可视距离，需要隐藏右侧箭头
                let timer = setTimeout(() => {
                    // 重新：获取最后一个元素距离左侧的距离
                    let lastItemOffsetLeft2 = this.childrenList[this.childrenList.length - 1].offsetLeft
                    console.log('lastItemOffsetLeft2-新的->', lastItemOffsetLeft2)
                    if (lastItemOffsetLeft2 + lastWidth <= lookWidth) {
                        this.showRightIcon = false
                    }
                    clearTimeout(timer)
                }, 200)
            }
        },
        // 点击左箭头（右侧滚动）
        handleLeftClick() {
            // 当点击次数大于0时才触发，这样当点击次数clickNum等于1的到时候，clickNum--等于0.根据计算属性的判断会隐藏掉左箭头
            if (this.clickNum > 0) {
                // 获取当前元素宽度
                let width = this.childrenList[this.clickNum - 1].getBoundingClientRect().width
                // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
                this.$refs.swiperScrollContent.style.marginLeft = `${this.lastLeft + width}px`
                this.lastLeft = width + this.lastLeft
                // 点击次数-1
                this.clickNum--
                // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
                if (this.clickNum < this.maxClickNum) {
                    this.showRightIcon = true
                }
            }
        },
        toFull() {
            const cn = getCn()
            const href = `//${cn}/digital`
            window.open(href, '_blank')
        },
        changeChangjing(item, index, type) {
            this.$router.options.routes = [
                {
                    path: '/login',
                    name: 'Login',
                    meta: { title: '登录', hideInMenu: true },
                    component: () => import('@/views/Login/login.vue')
                },
                {
                    path: '/404',
                    component: () => import('@/views/error-page/404'),
                    hidden: true
                }
            ]
            this.$store.dispatch('tagsView/changeStatusClear', [])
            if (type && type === 'options') {
                this.currentIndex = '' //下拉清空选中
                localStorage.setItem('currentIndex', -1) //清除样式持久化
            } else {
                localStorage.setItem('currentIndex', index) //持久化样式
                this.currentIndex = index //tab切换选中样式
            }

            let { children } = item
            if (children && children.length) {
                //如果无菜单 不跳转 渲染左侧菜单
                this.permissionList = children //重新渲染左侧显示菜单
                localStorage.setItem('mylist', JSON.stringify(children)) //切换重新缓存路由数据
                localStorage.setItem('initLeftMenu', JSON.stringify(children)) //缓存
                let path
                let scenarioId
                if (children[0].children && children[0].children.length) {
                    //判断有无子集
                    path = `${children[0].children[0].path}`
                    scenarioId = `${children[0].children[0].scenarioId}`
                } else {
                    path = `${children[0].path}`
                    scenarioId = `${children[0].scenarioId}`
                }
                console.log(children, '????1111')
                this.$router.push({ path: `${path}?${scenarioId}` })
                /*if(children[0].children && children[0].children.length === 1 && children[0].children[0].hideInMenu) {
						this.activeRoute = children[0].path
						this.$router.push({path: `${children[0].children[0].path}?${scenarioId}`})
					}else {
						this.activeRoute = path
						this.$router.push({path: `${path}?${scenarioId}`})
					}*/
            }
        },
        logout() {
            // 退出
            // localStorage.clear() // 不要全部清,导致日志弹窗每次登录都会弹出
            localStorage.removeItem('mylist')
            localStorage.removeItem('scenarioList')
            localStorage.removeItem('cad_userInfo')
            this.$router.push({ path: '/login' })
            window.location.reload()
            // resetRouter()
        },
        toEdit() {
            const userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
            this.$router.push({ path: '/baseSetting/unitEdit', query: { id: userInfo.unitId, type: 2 } })
        },
        getWaterMarkContent() {
            function isPoneAvailable(str) {
                var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/
                if (!myreg.test(str)) {
                    return false
                } else {
                    return true
                }
            }
            const userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
            const name = userInfo.username || userInfo.name
            const phone = userInfo.phone
            // const phone = 13139568009
            const showname = name && name.length > 5 ? name.substring(name.length - 5, name.length) : name
            const showphone = phone && isPoneAvailable(phone) ? phone.toString().substring(phone.toString().length - 4, phone.toString().length) : ''
            return (showname + showphone).trim()
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 45px 35px 20px 35px;
    font-size: 18px;
    .lineItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        /deep/ .el-button--text {
            font-size: 18px;
        }
        .rightContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            .tip {
                font-size: 14px;
            }
        }
    }
    .del {
        color: #e60707;
        font-size: 20px;
    }
}

.user-head {
    line-height: 60px;
    padding-right: 40px;
    cursor: pointer;
    float: right;
}
.phoneImg {
    padding-right: 20px;
    cursor: pointer;
    float: right;
    padding-top: 20px;
    width: 20px;
}
.layout {
    width: 100%;
    height: 100%;
    background: #f0f3f7;
    /*display: flex;*/
    overflow: hidden;

    .header {
        height: 60px;
        background: #f0f3f7;
        /*text-align: right;*/
        /*position: absolute;*/
        width: 100%;
        z-index: 222;
        background: #fff;
        /*display: flex;*/
        /*padding-right: 34px;*/
        /*	display: flex;
        align-items: center;
        justify-content: space-between;*/
        box-shadow: 0 1px 3px rgba(18, 18, 18, 0.1);
        border-bottom: 1px solid #eee;
        .header-center {
            width: 40%;
            margin-left: 15%;
            height: 100%;
            float: left;
            position: relative;
            .scroll-icon {
                position: absolute;
                top: 50%;
                margin-top: -30px;
                width: 70px;
                height: 60px;
                z-index: 9;
                cursor: pointer;
                text-align: center;
                line-height: 60px;
                color: #cccccc;
                &.left-icon {
                    left: -80px;
                    i {
                        font-size: 24px;
                        line-height: 60px;
                    }
                }
                &.right-icon {
                    right: -80px;
                    i {
                        font-size: 24px;
                        line-height: 60px;
                    }
                }
            }
        }

        .header-left {
            float: left;
            display: flex;
            align-items: center;
            .zhankai {
                margin-left: 15px;
                cursor: pointer;
                img {
                    padding-top: 7px;
                }
            }
            .logo {
                height: 60px;
                line-height: 60px;
                display: flex;
                align-items: center;
                float: left;
                img {
                    width: 40px;
                    height: 40px;
                    padding: 0 12px;
                    vertical-align: middle;
                }
                span {
                    font-size: 18px;
                    font-weight: 500;
                    color: #222222;
                    display: inline-block;
                }
            }
        }
    }
    .bottom-box {
        display: flex;
        height: 100%;
        width: 100%;
        .aside {
            width: 200px;
            background: #222222;
            /*		.logo {
              height: 70px;
              line-height: 70px;
              img {
                width: 40px;
                height: 40px;
                padding: 0 12px;
                vertical-align: middle;
              }
              span {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
              }
            }*/
            .menu {
                /*height: calc(100% - 70px);*/
                width: 100%;
                background: #222222;
            }
        }
        .container {
            height: 100%;
            background: #f0f3f7;
            width: calc(100% - 200px);
            position: relative;
            overflow-y: auto;
            .my-content {
                width: 100%;
                /*margin-top: 70px;*/
                height: calc(100% - 130px);
                padding: 12px 12px 0 12px;
                box-sizing: border-box;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.left-right-swiper-scroll-box {
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: all 0.3s;

    .swiper-scroll-content {
        white-space: nowrap;
        transition: all 0.3s;
        width: 100%;
        .liItem {
            display: inline-block;
            cursor: pointer;
            font-size: 18px;
            /*width: 168px;*/
            font-family: PingFangSC-Medium, PingFang SC;
            color: #222222;
            padding: 0 30px;
            height: 100%;
            line-height: 60px;
            /*background-color: #3FCF89;*/
            .dot {
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: #222222;
                border-radius: 50%;
                margin: 0 2px;
                line-height: 60px;
            }
        }
        .lastli {
            line-height: 65px;
        }
        .activeItem {
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            padding: 0 30px;
            height: 100%;
            /*line-height: 70px;*/
            background-color: #3fcf89;
        }
        .inactiveItem {
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
            color: #222222;
            padding: 0 30px;
            height: 100%;
            /*line-height: 70px;*/
        }
        ul,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        ul {
            display: flex;
            justify-content: flex-end;
            /*margin-left: 15px;*/
            height: 100%;
            .liItem {
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                color: #222222;
                padding: 0 30px;
                height: 100%;
                line-height: 70px;
                /*background-color: #3FCF89;*/
                .dot {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background-color: #222222;
                    border-radius: 50%;
                    margin: 0 2px;
                    line-height: 70px;
                }
            }
            .lastli {
                line-height: 65px;
            }
            .activeItem {
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                color: #ffffff;
                padding: 0 30px;
                height: 100%;
                /*line-height: 70px;*/
                background-color: #3fcf89;
            }
            .inactiveItem {
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                color: #222222;
                padding: 0 30px;
                height: 100%;
                /*line-height: 70px;*/
            }
        }
    }
}

.update-log {
    padding: 0 20px;
    .desc {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
    }
    .tips {
        margin: 10px 0;
        font-size: 16px;
        font-weight: 500;
        color: #409eff;
        line-height: 22px;
    }
    .detail-info {
        padding: 0 20px;
        color: #333333;
    }
    .tc {
        text-align: center;
    }
}
</style>
