import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


// 解决两次访问相同路由地址报错
/*const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	// console.log(location,'????')
	return originalPush.call(this, location).catch(err => err)
}*/

/**
 * meta{
 *  hideInMenu 是否在菜单中显示 （true:隐藏;false:显示）
 * }
 * 定义路由列表
 */
export const staticRoutes = [
	{
		path: '/login',
		name: 'Login',
		meta: { title: '登录', hideInMenu: true },
		component: () => import('@/views/Login/login.vue'),
	},
	{
		path: '/404',
		component: () => import('@/views/error-page/404'),
		hidden: true
	},
	// {
	// 	path: '/volunteer',
	// 	name: 'volunteer',
	//  component: Layout,
	// 	children: [
	// 		{
	// 			path: '/volunteer/volunteerGroups',
	// 			name: 'volunteerGroups',
	// 			component: () => import('@/views/volunteer/volunteerGroups/index.vue'),
	// 			meta: {title: '志愿团体', hideInMenu: false},
	// 		},
	// 		{
	// 			path: '/volunteer/volunteerGroups/add',
	// 			name: 'volunteerGroupsAdd',
	// 			component: () => import('@/views/volunteer/volunteerGroups/add.vue'),
	// 			meta: {title: '志愿团体新增', hideInMenu: true},
	// 		},
	// 		{
	// 			path: '/volunteer/volunteerGroups/details',
	// 			name: 'volunteerGroupsDetails',
	// 			component: () => import('@/views/volunteer/volunteerGroups/details.vue'),
	// 			meta: {title: '志愿团体详情', hideInMenu: true},
	// 		},
	// 		{
	// 			path: '/volunteer/volunteerManage',
	// 			name: 'volunteerManage',
	// 			component: () => import('@/views/volunteer/volunteerManage/index.vue'),
	// 			meta: {title: '志愿者管理', hideInMenu: false},
	// 		},
	// 		{
	// 			path: '/volunteer/volunteerManage/add',
	// 			name: 'volunteerManageAdd',
	// 			component: () => import('@/views/volunteer/volunteerManage/add.vue'),
	// 			meta: {title: '志愿者管理新增', hideInMenu: true},
	// 		},
	// 		{
	// 			path: '/volunteer/integralSystem',
	// 			name: 'integralSystem',
	// 			component: () => import('@/views/volunteer/integralSystem/index.vue'),
	// 			meta: {title: '积分体系', hideInMenu: false},
	// 		},
	// 		{
	// 			path: '/volunteer/integralSystem/add',
	// 			name: 'integralSystemAdd',
	// 			component: () => import('@/views/volunteer/integralSystem/add.vue'),
	// 			meta: {title: '积分体系新增', hideInMenu: true},
	// 		},
	// 		{
	// 			path: '/volunteer/integralManage',
	// 			name: 'integralManage',
	// 			component: () => import('@/views/volunteer/integralManage/index.vue'),
	// 			meta: {title: '积分管理', hideInMenu: false},
	// 		},
	// 		{
	// 			path: '/volunteer/integralManage/add',
	// 			name: 'integralManageAdd',
	// 			component: () => import('@/views/volunteer/integralManage/add.vue'),
	// 			meta: {title: '积分管理新增', hideInMenu: false},
	// 		},
	// 		{
	// 			path: '/volunteer/integralManage/integralRecord',
	// 			name: 'integralRecord',
	// 			component: () => import('@/views/volunteer/integralManage/integralRecord.vue'),
	// 			meta: {title: '积分记录', hideInMenu: true},
	// 		},
	// 		{
	// 			path: '/volunteer/integralManage/integralDetails',
	// 			name: 'integralDetails',
	// 			component: () => import('@/views/volunteer/integralManage/integralDetails.vue'),
	// 			meta: {title: '积分明细', hideInMenu: true},
	// 		},
	// 	]
	// }
/*	{
		path: '/',
		name: 'bedInfo',
		component: Layout,
		meta: {title: '家庭养老床位', hideInMenu: false, icon: 'icon-chuangweiguanli'},
		redirect: '/login',
		children: [
			{
				path: 'bedManage',
				name: 'bedManage',
				component: () => import('@/views/OlderBed/bedManage.vue'),
				meta: {title: '床位管理', hideInMenu: false},
			}]
	}*/
/*	{
		path: '/',
		name: 'bedInfo',
		component: Layout,
		meta: { title: '家庭养老床位', hideInMenu: false, icon: 'icon-chuangweiguanli' },
		redirect: '/login',
		children: [
			{
				path: 'bedManage',
				name: 'bedManage',
				component: () => import('@/views/OlderBed/bedManage.vue'),
				meta: { title: '床位管理', hideInMenu: false },
			},
			{
				path: 'disableList',
				name: 'disableList',
				component: () => import('@/views/OlderBed/disableList.vue'),
				meta: { title: '停发列表', hideInMenu: false },
			},
			{
				path: 'serveDetail',
				name: 'serveDetail',
				component: () => import('@/views/PensionSupervision/workList.vue'),
				meta: { title: '服务工单', hideInMenu: false },
			},
			{
				path: 'riskPage',
				name: 'riskPage',
				component: () => import('@/views/OlderBed/riskPage.vue'),
				meta: { title: '床位居家安全风险', hideInMenu: false },
			},
		],
	},
	{
		path: '/supervision',
		name: 'PensionSupervision',
		component: Layout,
		meta: { title: '养老服务监管', hideInMenu: false, icon: 'icon-shujutongji' },
		redirect: '/supervision/workList',
		children: [
			{
				path: 'workList',
				name: 'workList',
				component: () => import('@/views/PensionSupervision/workList.vue'),
				meta: { title: '服务工单', hideInMenu: false },
			},
		],
	},
	/!*	{
		path: '/organManage',
		name: 'OrganManage',
		component: Layout,
		meta: { title: '机构管理', hideInMenu: false, icon: 'icon-jigouxinxi' },
		redirect: '/organManage/organList',
		children: [
			{
				path: 'organList',
				name: 'organList',
				component: () => import('@/views/OrganManage/organList.vue'),
				meta: { title: '机构列表', hideInMenu: false },
			},
		],
	},*!/

	{
		path: '/baseSetting',
		name: 'baseSetting',
		component: Layout,
		meta: { title: '基础设置', hideInMenu: false, icon: 'icon-shezhi' },
		redirect: '/baseSetting/unitManagement',
		children: [
			{
				path: 'unitManagement',
				name: 'unitManagement',
				component: () => import('@/views/baseSetting/unitManagement'),
				meta: { title: '单位管理', hideInMenu: false },
			},
			{
				path: 'unitAdd',
				name: 'unitAdd',
				component: () => import('@/views/baseSetting/unitManagement/unitAdd.vue'),
				meta: { title: '新增管理', hideInMenu: true },
			},
			{
				path: 'unitEdit',
				name: 'unitEdit',
				component: () => import('@/views/baseSetting/unitManagement/unitEdit.vue'),
				meta: { title: '编辑管理', hideInMenu: true },
			},
			{
				path: 'unitInfoDetail',
				name: 'unitInfoDetail',
				component: () => import('@/views/baseSetting/unitManagement/unitInfoDetail.vue'),
				meta: { title: '详情管理', hideInMenu: true },
			},
			{
				path: 'departmentManagement',
				name: 'departmentManagement',
				component: () => import('@/views/baseSetting/departmentManagement'),
				meta: { title: '部门管理', hideInMenu: false },
			},
			{
				path: 'userManagement',
				name: 'userManagement',
				component: () => import('@/views/baseSetting/userManagement'),
				meta: { title: '用户管理', hideInMenu: false },
			},
		],
	},
	{
		path: '/institutionalInformation',
		name: 'institutionalInformation',
		component: Layout,
		meta: { title: '机构信息', hideInMenu: false, icon: 'icon-jigouxinxi' },
		redirect: '/institutionalInformation/elderlyCareFacilities',
		children: [
			{
				path: 'elderlyCareFacilities',
				name: 'elderlyCareFacilities',
				component: () => import('@/views/institutionalInformation/elderlyCareFacilities'),
				meta: { title: '社区养老服务设施', hideInMenu: false },
			},
			{
				path: 'elderlyCareFacilities/elderlyCareFacilitiesDetail',
				name: 'elderlyCareFacilitiesDetail',
				component: () => import('@/views/institutionalInformation/elderlyCareFacilities/elderlyCareFacilitiesDetail'),
				meta: { title: '查看', hideInMenu: true },
			},

			{
				path: 'institutionalFiling',
				name: 'institutionalFiling',
				component: () => import('@/views/institutionalInformation/institutionalFiling'),
				meta: { title: '机构备案', hideInMenu: false },
			},
			{
				path: 'institutionalFiling/institutionalFilingDetail',
				name: 'institutionalFilingDetail',
				component: () => import('@/views/institutionalInformation/institutionalFiling/institutionalFilingDetail'),
				meta: { title: '查看', hideInMenu: true },
			},

			{
				path: 'oldMarketPlayers',
				name: 'oldMarketPlayers',
				component: () => import('@/views/institutionalInformation/oldMarketPlayers'),
				meta: { title: '涉老市场主体', hideInMenu: false },
			},
			{
				path: 'oldMarketPlayers/oldMarketPlayersDetail',
				name: 'oldMarketPlayersDetail',
				component: () => import('@/views/institutionalInformation/oldMarketPlayers/oldMarketPlayersDetail'),
				meta: { title: '查看', hideInMenu: true },
			},
		],
	}*/
]
const createRouter = () => new VueRouter({
	scrollBehavior: () => ({ y: 0 }),
	routes: staticRoutes
})

const router = createRouter()

// 重置路由
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router
