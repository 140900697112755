import request from '../utils/request'
import config from './config'

// 登录
export function login(data) {
    return request({
        url: `${config.CIVIL_HOST}/civilCount/login`,
        method: 'post',
        data
    })
}

// 修改密码
export function changePassword(data) {
    return request({
        url: `${config.CIVIL_HOST}/civilCount/changePassword`,
        method: 'post',
        data
    })
}

// 查询应用场景菜单树
export function getMenuTree(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionApplicationDispose/menuPrimaryTree`,
        method: 'get',
        params
    })
}

// 查询应用场景菜单树/子账号
export function setUpMenuTree(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionApplicationDispose/setUpMenuTree`,
        method: 'post',
        data
    })
}

// 渝快政登录
export function ykzLogin(data) {
    return request({
        url: `${config.CIVIL_HOST}/ykz/login`,
        method: 'post',
        data
    })
}

// 渝快政绑定
export function ykzBind(data) {
    return request({
        url: `${config.CIVIL_HOST}/ykz/bind`,
        method: 'post',
        data
    })
}
