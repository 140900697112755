<template>
  <div>
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
  export default {
    name: "AppMain.vue"
  }
</script>

<style scoped>

</style>
