<template>
  <el-image-viewer
    :on-close="closeViewer"
    :url-list="urlList"
  />
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: "imageView",
  components: {ElImageViewer},
  methods: {
    closeViewer() {
      // 关闭组件后从父级dom销毁这个挂载
      this.$el.parentNode.removeChild(this.$el)
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-image-viewer__close{
  color:white;
}
</style>
