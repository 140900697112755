import axios from 'axios'
import { message } from '@/utils/resetMessage'
import router from '@/router/index'
import { SPECIAL_URL_LIST } from '../utils/httpData'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    //   timeout: 15000
    timeout: 30000
})

// request interceptor
service.interceptors.request.use(
    config => {
        const userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
        if (userInfo?.token) {
            config.headers['Authorization'] = userInfo.token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (response.headers['content-type'] === 'multipart/form-data') {
            return res
        }
        // 文件流拦截
        if (response.request.responseType === 'blob') {
            return Promise.resolve(res)
        }

        const url = response.config.url
        // 拦截特殊地址
        const _URL = url.substring(url.indexOf('pension-cab') + 11)
        if (SPECIAL_URL_LIST.indexOf(_URL) != -1) {
            return Promise.resolve(res)
        }

        if (res.state !== 0 && res.code !== 20000 && res.state !== 10006) {
            // 接口错误
            // 登录失效
            // if (res.state === 3 || res.state === 10011) {
            if (res.state === 3) {
                message({
                    message: '您未登录或者登陆秘钥已过期,请重新登陆',
                    type: 'error',
                    duration: 1000
                })
                router.push({
                    path: '/login',
                    query: { redirect: router.currentRoute.fullPath } // 登录后再跳回此页面时要做的配置
                })
                localStorage.clear() // 清除用户数据
            } else {
                message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
            }
            // return Promise.reject(new Error(res.message || 'Error'))
            return Promise.reject(res)
        } else {
            // return res
            return Promise.resolve(res)
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
