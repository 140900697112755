const developReg = /(localhost)|(0\.0\.0\.0)|(192.168)/
const testReg = /(cirbd.cn)/

let cn = window.location.host
if (developReg.test(location.href)) {
    cn = 'xiantao.cirbd.cn'
}
const DEVELOP = {
    MAIN_HOST: '//' + 'xiantao.cirbd.cn' + '/pension-backend',
    CIVIL_HOST: '//219.151.151.215:8070/pension-cab',
    BACK_HOST: '//pensiontest.cirbd.cn/zuul'
    // CIVIL_HOST: '//192.168.4.99:8070/cab'
}

const PROFUCT = {
    MAIN_HOST: '//' + 'xiantao.huinian.net.cn' + '/pension-backend',
    CIVIL_HOST: '//' + cn + '/pension-cab',
    BACK_HOST: '//smartpension.cirbd.cn/zuul'
}

var config = PROFUCT
if (developReg.test(location.href) || testReg.test(location.href)) {
    config = DEVELOP
}
export default config
