/*
 * @Author: 刘小二
 * @Date: 2021-07-15 14:43:27
 * @LastEditTime: 2021-07-15 15:00:27
 * @LastEditors: Please set LastEditors
 * @Description: 添加水印
 * @FilePath: /huashijc_MeetingSys/src/common/warterMark.js
 */
'use strict'
/** * 获取文本px宽度*
 * @param text{String}: 字符串
 * @param fontStyle{String}: 字符样式
 **/
function getTextWith(text, fontStyle) {
    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')
    context.font = fontStyle || '16px' // 设置字体样式
    var dimension = context.measureText(text)
    return dimension.width
}

const watermark = {}

const setWatermark = (tip, str) => {
    const id = '1.23452384164.123412415'

    if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id))
    }

    const can = document.createElement('canvas')
    const strWidth = Math.ceil(getTextWith(str, 'normal 24px Vedana'))
    // console.log(strWidth, 'strWidth')
    const jg = 180
    can.width = strWidth + jg
    can.height = Math.ceil(can.width / 2.625)
    can.style.border = '1px solid #000'
    const cans = can.getContext('2d')
    cans.rotate((-20 * Math.PI) / 180)
    cans.font = '18px Vedana'
    cans.fillStyle = 'rgba(200, 200, 200, 0.15)'
    cans.textAlign = 'left'
    cans.textBaseline = 'Bottom'
    cans.fillText(tip, -10, can.height)
    if (str) {
        cans.fillText(str, 110, can.height)
    }

    const div = document.createElement('div')
    div.id = id
    div.style.pointerEvents = 'none'
    div.style.top = '35px'
    div.style.left = '0px'
    div.style.position = 'fixed'
    div.style.zIndex = '100000'
    div.style.width = document.documentElement.clientWidth + 'px'
    div.style.height = document.documentElement.clientHeight + 'px'
    div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
    document.body.appendChild(div)
    return id
}

// 该方法只允许调用一次
watermark.set = (tip, str) => {
    let id = setWatermark(tip, str)
    setInterval(() => {
        if (document.getElementById(id) === null) {
            id = setWatermark(tip, str)
        }
    }, 500)
    window.onresize = () => {
        setWatermark(tip, str)
    }
}

const outWatermark = id => {
    if (document.getElementById(id) !== null) {
        const div = document.getElementById(id)
        div.style.display = 'none'
    }
}
watermark.out = () => {
    const str = '1.23452384164.123412415'
    outWatermark(str)
}

export default watermark
