import router, { staticRoutes, resetRouter } from './router'
import Layout from './Layout/layoutIndex'
import store from './store'
import { getCn } from '@/utils'

const whiteList = ['/login'] //白名单 直接进入
router.beforeEach(async (to, from, next) => {
    // 判断路由指向是否在需要过滤的路由地址数组里
    // 如果在，则直接跳进页面，无需判断
    if (whiteList.indexOf(to.path) !== -1) {
        next()
        return false
    }
    // 跳转智慧居家安全监测大屏
    if (to.path === '/homeSaveScreen') {
        const cn = getCn()
        window.open(`http://${cn}/homeSave`, '_blank')
        return
    }
    // store.dispatch("permission/generateRoutes")
    // console.log(router.options.routes, 'router.options.routes.length\n')
    // console.log(router.options.routes.length, 'router.options.routes.length\n')
    /*  if(router.options.routes.length == 0) {
    // location.reload();
  }*/
    if (router.options.routes.length == 2) {
        //判断静态路由是否只有一个 否则死循环
        // 获取token和原始路由数组
        const userInfo = JSON.parse(window.localStorage.getItem('cad_userInfo')) ?? ''
        const arr = JSON.parse(localStorage.getItem('mylist')) ?? []
        // 当token和原始路由都存在的时候
        if (userInfo.token && arr.length) onFilterRoutes(to, next, arr)
        // 执行路由过滤和跳转
        else next({ path: '/login', replace: true })
    } else {
        if (to.matched.length) {
            next()
        } else {
            if (to.path === '/') {
                next({ path: '/login', replace: true })
            } else {
                next({ path: '/404', replace: true })
            }
        }
    }
})

// 路由拼接
function loadView(view) {
    return () => import(`@/views/${view}.vue`)
}

// 路由过滤和跳转
async function onFilterRoutes(to, next, e) {
    const routes = await filterASyncRoutes(e) // 路由过滤
    routes.sort((a, b) => a['id'] - b['id'])
    routes.forEach(item => {
        router.options.routes.push(item)
    })
    resetRouter()
    router.addRoutes(routes)

    next({ ...to, replace: false }) //确保路由添加完成
}

// 路由过滤   遍历路由 转换为组件对象和路径
function filterASyncRoutes(data) {
    const routes = data.filter(item => {
        if (item['component'] === 'Layout') item.component = Layout
        else item['component'] = loadView(item['component'])
        item.meta = { title: item.title, icon: item.icon, hideInMenu: item.hideInMenu, activeIndex: item.hideInMenu ? `/${item.path.split('/')[1]}` : item.path }
        // 路由递归，转换组件对象和路径
        if (item['children'] && item['children'].length) item['children'] = filterASyncRoutes(item.children)
        return true
    })
    return routes
}
